function togglePane(target: string) {
    const elements = Array.from(document.querySelectorAll(`[data-toggle-target="${target}"]`));

    for (const element of elements) {
        if (element.classList.contains('gui-toggle-pane__active')) {
            element.classList.remove('gui-toggle-pane__active');
        } else {
            element.classList.add('gui-toggle-pane__active');
        }
    }
}

export function guiToggle(nodes: NodeList): void {
    const elements = Array.from(nodes) as HTMLElement[];
    elements.forEach(element => {
        element.addEventListener('click', (event) => {
            const target = event.target as Element;
            const toggleAttribute = target.getAttribute('data-toggle');
            if (toggleAttribute) {
                if (target.classList.contains('gui-toggle-btn__active')) {
                    target.classList.remove('gui-toggle-btn__active');
                } else {
                    target.classList.add('gui-toggle-btn__active');
                }
                togglePane(toggleAttribute);
            }
        });
    });
}
